.Button {
  color: white;
  background-color: black;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  padding: 4px 17px;
}

.Intro {
  font-size: 1.25em;
  font-weight: bold;
}

.Nav {
  text-align: center;
}

.Portfolio {
  padding: 5px 30px 10px 30px;
}

.Title {
  font-size: 2.5em;
  font-weight: bold;
  text-align: center;
}

@media screen and (max-width: 500px) {
  .Portfolio {
    padding: 5px 10px 10px 10px;
    }
}
